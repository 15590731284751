import React from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { motion } from 'framer-motion'
type FormData = {
    email: string;
    password: string;
}
type PasswordProps = {
    register: UseFormRegister<FormData>
    errors: FieldErrors<FormData>
}
const Password: React.FC<PasswordProps> = ({ register, errors }) => {
    return (
        <div>
            <Label htmlFor="password" className="sr-only">Password</Label>
            <Input
                id="password"
                type="password"
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-400 placeholder-gray-500 transition duration-500 ease-in-out focus:outline-none focus:z-10 sm:text-sm"
                placeholder="Password"
                {...register("password", {
                    required: "Password cannot be empty",
                })}
            />
            {/* Password Validation Error */}
            {errors.password && (
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="text-sm text-red-500"
                >
                    {errors.password.message}
                </motion.div>
            )}
        </div>
    )
}

export default Password