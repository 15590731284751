"use client"

import { Button } from "@/components/ui/button";
import { Loader2 } from 'lucide-react';
import TermsAndConditions from "@/components/Login/TermsAndConditions";
import Email from "@/components/Login/Email";
import Password from "@/components/Login/Password";
import { useLogin } from "@/app/hooks/useLogin";
import Link from "next/link";
import { useRouter } from "next/navigation";

interface FormData {
  email: string;
  password: string;
}

export default function LoginForm() {
  const { register, handleSubmit, errors, isLoading, login } = useLogin();
  const router = useRouter();

  const onSubmit = async (data: FormData) => {
    await login(data);
  };


  return (
    <div className="flex h-screen justify-center items-center bg-background">
      <div className="w-full lg:w-1/2 flex items-center justify-center p-12">
        <div className="w-full max-w-md space-y-8">
          <FormTitle />
          
          <form className="mt-8 space-y-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-6">
              <Email register={register} errors={errors} />
              <Password register={register} errors={errors} />
            </div>
            <div className="mb-8 flex items-center justify-between">
              <Link
                href="#"
                className="text-sm text-primary hover:underline"
                onClick={(e) => {
                  e.preventDefault();
                  router.push("/reset-password");
                }}
              >
                Forgot your password?
              </Link>
            </div>

            <div>
              <Button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 rounded-md bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  "Continue"
                )}
              </Button>
            </div>
          </form>
          <TermsAndConditions />
        </div>
      </div>
    </div>
  );
}

const FormTitle = () => {
  return (
    <h2 className="mt-6 text-center text-3xl font-extrabold text-foreground">
      Sign in to your account
    </h2>
  );
}