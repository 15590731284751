import { motion } from 'framer-motion'
import React from 'react'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { UseFormRegister, FieldErrors } from 'react-hook-form'

type FormData = {
    email: string;
    password: string;
}

type EmailProps = {
    register: UseFormRegister<FormData>
    errors: FieldErrors<FormData>
}

const Email: React.FC<EmailProps> = ({ register, errors }) => {
    return (
        <div>
            {/* Email Input */}
            <Label htmlFor="email" className="sr-only">Email</Label>
            <Input
                id="email"
                type="text"
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-400 placeholder-gray-500 transition duration-500 ease-in-out focus:outline-none focus:z-10 sm:text-sm"
                placeholder="Email"
                {...register("email", {
                    required: "Email cannot be empty",
                    pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: "Invalid email format",
                    },
                })}
            />
            {/* Email Validation Error */}
            {errors.email && (
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="text-sm text-red-500 mt-1"
                >
                    {errors.email?.message}
                </motion.div>
            )}
        </div>
    )
}

export default Email
