import { useState } from "react";
import { useForm } from "react-hook-form";
import { createClient } from "../utils/supabase/clients";
import { toast } from "sonner";
import { useRouter } from "next/navigation";

interface FormData {
  email: string;
  password: string;
}

export const useLogin = () => {
    const supabase = createClient();
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({ mode: "onSubmit" });

    const login = async (data: FormData) => {
        try {
            setIsLoading(true);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { data: user, error } = await supabase.auth.signInWithPassword({
                email: data.email,
                password: data.password,
            });
        
            if (error) {
                throw error;
            }
            toast.success('Login successful', {
                style: {
                    background: '#10B981',
                    color: 'white',
                    },
                className: 'border-green-500',
            });

            router.push('/home');

        } catch (error) {
            toast.error(error instanceof Error ? error.message : 'Failed to login', {
                style: {
                background: '#EF4444',
                color: 'white',
                },
                className: 'border-red-500',
            });
            return null;
        } finally {
            setIsLoading(false);
        }
        
    }
    return {
        register,
        handleSubmit,
        login,
        errors,
        isLoading,
    }
}