import React from 'react'
import Link from 'next/link'

const TermsAndConditions = () => {
    return (
        <p className="mt-2 text-center text-sm text-muted-foreground">
            By clicking continue, you agree to our{" "}
            <Link href="/terms" className="font-medium text-primary hover:underline">
                Terms of Service
            </Link>{" "}
            and{" "}
            <a
                href="http://privacy.volya.ai"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-primary hover:underline"
            >
                Privacy Policy
            </a>.
        </p>
    )
}

export default TermsAndConditions;